import React from "react";

export default function Centered(props) {
  
  let styles = {
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    ...props.style,
  }

  return (
    <div style={styles} className={props.className}>
      {props.children}
    </div>
  )
}