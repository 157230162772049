import React from "react"
import Spacer from "../components/spacer/Spacer"
import Centered from "../components/centered/Centered"
import lion from "../images/hiking-challenge/designer-lion-hiker@2x.png"
import makeALiving from "../images/hiking-challenge/make-a-living-designing-logos-hardcover@2x.png"
import webDesignBoringUICourse from "../images/hiking-challenge/wed-design-boring-ui-course@2x.png"
import webDesignCourse from "../images/hiking-challenge/web-design-course@2x.png"
import fspOff from "../images/hiking-challenge/fps+off@2x.png"
import fsp from "../images/hiking-challenge/fsp@2x.png"
import designerfoundryprize from "../images/hiking-challenge/designer-foundry-prize@2x.png"
import lsGraphicsprize from "../images/hiking-challenge/ls-mockups@2x.png"
import benditoprize from "../images/hiking-challenge/bendito@2x.png"
import trueGritprize from "../images/hiking-challenge/truegrit-prize@2x.png"
import markusDaumPrize from "../images/hiking-challenge/markus-daum-prize.png"
import BenBurnsPrize1 from "../images/hiking-challenge/ben-burn-consultation@2x.png"
import BenBurnsPrize2 from "../images/hiking-challenge/brand-style-guide@2x.png"

import imagePrize1 from "../images/hiking-challenge/bravemark-prizes1@2x.png"
import imagePrize2 from "../images/hiking-challenge/bravemark-prize2@2x.png"
import designBuddiesSvg from "../images/hiking-challenge/design-buddies.svg"
import dontBeBoringCourse from "../images/hiking-challenge/dont-be-boring-course@2x.png"
import logoGeek from "../images/hiking-challenge/logo-geek.png"
import hype4Academy from "../images/hiking-challenge/hype4academy.svg"
import pangrampangram from "../images/hiking-challenge/pangrampangram.svg"
import theDesignerFoundry from "../images/hiking-challenge/thedesignerfoundry.svg"
import lsGraphics from "../images/hiking-challenge/ls-graphics-logo.png"
import benditoLogo from "../images/hiking-challenge/bendito-logo-horizontal.svg"
import trueGritLogo from "../images/hiking-challenge/true-grit-logo.svg"
import markusDaumLogo from "../images/hiking-challenge/logo-markus.svg"
import theFuturLogo from "../images/hiking-challenge/the-futur-logo.svg"

import avatarGrace from "../images/hiking-challenge/grace-ling@2x.png"
import avatarBenBurns from "../images/hiking-challenge/ben-burns@2x.png"
import avatarIan from "../images/hiking-challenge/ian-paget@2x.png"
import avatarJohan from "../images/hiking-challenge/johan-steneros@2x.png"
import avatarMarcus from "../images/hiking-challenge/marcus@2x.png"
import avatarMichal from "../images/hiking-challenge/michal-malewicz@2x.png"
import avatarMarkusDaum from "../images/hiking-challenge/markus-daum@2x.png"
import avatarBravemarkCommunity from "../images/hiking-challenge/bravemark-community.png"

import OGImage from "../images/hiking-challenge/og-image.jpeg"
import Page from "../components/page/Page";
import "../styles/index.scss";
import { Image } from "../components/image/Image";
import Logo from "../components/icons/Logo"
import { Button } from "../components/form/Button"
import { Helmet } from "react-helmet"


const markusDaumLink = "https://www.markusdaum.net/"
const theDesignerFoundryLink = "https://www.thedesignersfoundry.com/"
const benditoLink = "https://benditomockup.com/"
const pangrampangramLink = "https://pangrampangram.com"
const logoGeekLink = "https://logogeek.uk/"
const hype4AcademyLink = "https://hype4.academy/"
const bravemarkLink = "https://bravemark.co"
const designBuddiesLink = "https://www.designbuddies.community/"
const trueGritLink = "https://www.truegrittexturesupply.com/"
const lsGraphicsLink = "https://www.ls.graphics/"
const BenBurnsLink = "http://www.mrbenburns.com/"
const theFuturLink = "https://thefutur.com/"


// markup
const IndexPage = () => {
  return (
    <Page mainClasses="hiking-page">
      <Helmet>
        <title>Designer Hikes Brand Brief Challenge</title>
        <meta name="title" content="Designer Hikes Brand Brief Challenge • Bravemark" />
        <meta property="og:title" content="Designer Hikes Brand Brief Challenge" />
        <meta property="og:image" content={OGImage} />
        <meta property="og:description" content="Take part in the most awesome brand design challenge this year and get a chance to win some awesome prizes." />
        <meta property="og:url" content="https://www.bravemark.co/designer-hikes-brand-brief-challenge/" />
        <meta property="og:locale" content="en_US"/>
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Designer Hikes Brand Brief Challenge • Bravemark" />
        <meta name="twitter:title" content="Designer Hikes Brand Brief Challenge" />
        <meta name="twitter:image" content={OGImage} />
        <meta name="twitter:description" content="Take part in the most awesome brand identity design challenge this year and get a chance to win some awesome prizes." />
        <meta name="twitter:url" content="https://www.bravemark.co/designer-hikes-brand-brief-challenge/" />
      </Helmet>
      <Spacer amount="90" hideOnNarrowDevices={true} />

      <Centered>

        <Intro logoTitle="Lion" />

        <Section emoticon="🌲👨‍🎨🌲🌲👩‍🎨🌲" title="What is Designer Hikes" classes="what">
          <p>
            <b>Designer Hikes</b> is a new global non-profit community of graphic designers with the mission of organizing in-person meetups in their towns to get together and go out for nature hikes. The idea behind this community is to get designers of all sorts together, have a bit of fun, get to know other peers in their locality and build friendships and connections while taking time away from the computers to do healthy walks in nature.
          </p>
          <p>
            <b>Designer Hikes</b> is owned and organized by its members. <b>It’s an Open Source Design Community</b> where there is no owner, and everything is decided and voted together as a group. There will be a core team of organizers and ambassadors that will help run the day-to-day activities, such as managing the social networks, emails and websites and pushing to organize local meetwalks. Everyone is welcome to join and provide their input to help the community evolve. <b>The only requirement there is that you are a graphic designer of some sort</b>. 🙂
          </p>
          <p>
            To get this all started, we need a brand identity for our community. This takes us back to the brand brief challenge. The idea with the challenge is to get designers from all over the world involved to submit designs so that we can select the best concept for our new community identity. 
          </p>
          <p>
            Our sponsors will provide various prizes for the top submissions, and we will have a panel of judges and also organize a community vote. One thing that is very important to note is that the winning brand identity will have to be handed off under the <b>Creative Commons License</b>, and the designer of the selected brand identity will also have the option to become the brand identity officer of our community if he so wishes to. 
          </p>
          <p>
            If you are still here reading and want to take part in the challenge or want to support us in any way, keep on reading. 👇
          </p>
        </Section>

        <Section emoticon="🏕" title="The Brand Brief" classes="brief">
          <p>
            We seek to develop a brand identity that encapsulates the spirit of a nonprofit global community of graphic designers who aim to regularly meet locally to go on mountain hiking trips. This community brings together graphic design professionals of all levels and of all skills who share a passion for design and spending time in the wilderness. The brand identity should reflect our values, mission, and a unique blend of design and outdoor adventure.
          </p>

          <h3>Mission and Values</h3>

          <p>
            Our community's mission is to unite designers who are passionate about mountain hiking, fostering creativity, collaboration, and environmental responsibility. Our core values include creativity, exploration, sustainability, and community.
          </p>

          <div className="separator">——</div>

          <h3>Target Audience</h3>

          <p>
            Graphic Design professionals of all levels and ages (graphic designers, illustrators, web designers, motion designers, UX/UI designers, 3D designers, brand identity designers etc.) who are passionate about mountain hiking. Outdoor enthusiasts and adventurers who appreciate the intersection of design and nature. Those who have an interest in sustainable and eco-friendly practices in both design and outdoor activities.
          </p>

          <div className="separator">——</div>

          <h3>Requirements</h3>

          <p>
            The brand identity needs to look professional, unique, and identifiable. The logo should be created to work on various mediums, such as social media and websites, and different types of merchandise like caps, t-shirts, and hoodies.
          </p>

          <div className="separator">——</div>

          <h3>Design Components</h3>

          <p>
            <span className="emphasize">Logo:</span> Create a logo that represents both the mountain hiking and design aspects of our community. It should be memorable and adaptable for various applications.
          </p>

          <p>
            <span className="emphasize">Color Palette:</span> Develop a color scheme that conveys the vibrancy of outdoor landscapes while maintaining a professional and design-focused feel.
          </p>

          <p>
            <span className="emphasize">Typography:</span> Choose typefaces that can balance modern design aesthetics with the concept of nature or being outdoor.
          </p>

          <p>
            <span className="emphasize">Visual Elements:</span> Integrate elements such as illustrations, patterns, or photography that are related to graphic design and nature or hiking.
          </p>

          <div className="separator">——</div>

          <h3>Deliverables</h3>

          <div className="deliverable">
            <div className="emphasize">Logo Design Lockups</div>
            <div className="sub">
              (You will only need to submit the final vector files and assets if your design is chosen as the winner)
            </div>
          </div>
          
          <div className="deliverable emphasize">Color Palette</div>

          <div className="deliverable">
            <div className="emphasize">Typography</div>
            <div className="sub">
              (Google Fonts or open source, preferably)
            </div>
          </div>

          <div className="deliverable emphasize">Mockup Designs</div>

          <div className="deliverable emphasize">Brand Presentation via Bravemark</div>

        </Section>

        <Section emoticon="⏰" title="Deadline" classes="deadline">
          
          <p>Submit your design by publishing your presentation on Bravemark before the end of day on the <span className="date">30th of March.</span></p>

        </Section>

        <Section emoticon="🏆" title="The Prizes" classes="prizes">
          <Prize emoticon="🥇" title="1st Prize.">
          
            <SubPrize
              title={<>1 hr Consultation Session with Mr. Ben Burns + Brand Style Guide Kit <br /></>}
              subtitle={<div>One-to-one consulting session about growing your creative business and finding clients with the one and only Mr. Ben Burns. Valued at $500. Read more about his coaching <a href="https://thefutur.com/people/ben-burns" target="_blank">here</a>. Plus, an easy-to-use, 30+ page Brand Book Template for Indesign, Keynote and Adobe XD. Valued at $79. Read more about the Brand Style Kit <a href="https://thefutur.com/course/brand-style-guide-kit" target="_blank">here</a>.</div>}
              authors={[{
                name: "Mr. Ben Burns",
                link: BenBurnsLink,
              }, {
                name: "The Futur",
                link: theFuturLink,
              }]}
              image={{
                src: BenBurnsPrize1,
                width: "676",
                height: "224",
              }}
            />

            <SubPrize
              title={<>Markus Daum “Logos and Symbols” - Self Published Hardcover<br /></>}
              subtitle={<div>The master logo designer Markus Daum showcases 85 logo designs in this self published book. Read more about the book <a href="https://www.markusdaum.net/book/" target="_blank">here</a>.</div>}
              authors={[{
                name: "Markus Daum",
                link: markusDaumLink,
              }]}
              image={{
                src: markusDaumPrize,
                width: "295",
                height: "180",
              }}
            />

            <SubPrize
              title={<>“Make a Living Designing Logos” Printed Hardcover<br />+ Private Circle Logo Geek Community Membership</>}
              subtitle={<div>A book that should be on every brand designer’s bookshelf. Read more about the book <a href="https://www.kickstarter.com/projects/logogeek/make-a-living-designing-logos" target="_blank">here</a>. Valued at $30.</div>}
              authors={[{
                name: "Logo Geek",
                link: logoGeekLink,
              }]}
              image={{
                src: makeALiving,
                width: "295",
                height: "180",
              }}
            />

            <SubPrize
              title="Pangram Pangram Font Starter Pack + OFF Set Pack"
              subtitle={<div>57 Types / 643 Styles Total / All Formats / 30 PSD Mockups. Valued at $34.<br/>Read more about their font packs <a href="https://pangrampangram.com/products/fsp" target="_blank">here</a>.</div>}
              authors={[{
                name: "Pangram Pangram",
                link: pangrampangramLink,
              }]}
              image={{
                src: fspOff,
                width: "337",
                height: "180",
              }}
            />

            <SubPrize
              title="Web Design Course & Boring UI Course"
              subtitle={<div>The perfect courses for brand designers looking to get into Web and UI Design. Valued at $105. Read more about their courses <a href="https://hype4.academy/video-courses/boring-ui-course" target="_blank">here</a>.</div>}
              authors={[{
                name: "Hype4 Academy",
                link: hype4AcademyLink,
              }]}
              image={{
                src: webDesignBoringUICourse,
                width: "330",
                height: "180",
              }}
            />

            <SubPrize
              title="Bendito Mockup 50% Discount Code"
              subtitle={<div>Get a huge 50% discount code on their shop. Used by some of the greatest design agencies and brands. Check out their mockups <a href={benditoLink} target="_blank">here</a>.</div>}
              authors={[{
                name: "Bendito Mockup",
                link: benditoLink,
              }]}
              image={{
                src: benditoprize,
                width: "411",
                height: "180",
              }}
            />

            <SubPrize
              title="The Designers Foundry $50 Store Credit"
              subtitle={<div>Get a $50 discount code to add some unique new fonts to your font arsenal.<br />See their typefaces <a href="https://www.thedesignersfoundry.com/typefaces" target="_blank">here</a>.</div>}
              authors={[{
                name: "The Designers Foundry",
                link: theDesignerFoundryLink,
              }]}
              image={{
                src: designerfoundryprize,
                width: "411",
                height: "180",
              }}
            />

            <SubPrize
              title="LS Graphics Unlimited Access for 1 Year"
              subtitle={<div>Get unlimited access to their mockups, illustrations, and Figma Plugin for one full year. Check it out <a href="https://www.ls.graphics/unlimited-access" target="_blank">here</a>. Valued at $120</div>}
              authors={[{
                name: "LS Graphics",
                link: lsGraphicsLink,
              }]}
              image={{
                src: lsGraphicsprize,
                width: "415",
                height: "208",
              }}
            />

            <SubPrize
              title="True Grit $50 Store Credit"
              subtitle={<div>Get a $50 discount code to buy their awesome textures, brushes, swatches, actions, effects and bundles. See all their resources <a href={trueGritLink} target="_blank"> here</a>.</div>}
              authors={[{
                name: "True Grit",
                link: trueGritLink,
              }]}
              image={{
                src: trueGritprize,
                width: "411",
                height: "263",
              }}
            />

            <SubPrize
              title="Get featured on Design Buddies Discord Community"
              subtitle={<div>Get exposure to the largest graphic design community of 100,000+ designers. <a href="https://discord.gg/designbuddies" target="_blank">Join their Discord community here</a>.</div>}
              authors={[{
                name: "Design Buddies",
                link: designBuddiesLink,
              }]}
              image={{
                src: designBuddiesSvg,
                width: "304",
                height: "49",
              }}
            />

            <SubPrize
              title="2 Years of Bravemark PRO + Bravemark “MAKE IT ROAR” T-shirt + Holographic Stickers"
              subtitle={<div>Get a 2 Year PRO subscription to their tool to create online brand guidelines and unique branded swag. Read more about Bravemark <a href="https://www.bravemark.co" target="_blank">here</a>. Valued at $505.</div>}
              authors={[{
                name: "Bravemark",
                link: bravemarkLink,
              }]}
              image={{
                src: imagePrize1,
                width: "588",
                height: "180",
              }}
            />
          </Prize>

          <Prize emoticon="🥈" title="2nd Prize.">

            <SubPrize
              title="“Brand Style Guide Kit by Mr. Ben Burns"
              subtitle={<div>An easy-to-use, 30+ page brand book template for Indesign, Keynote and Adobe XD. Valued at $79. Read more about the Brand Style Kit <a href="https://thefutur.com/course/brand-style-guide-kit" target="_blank">here</a>.</div>}
              authors={[{
                name: "Mr. Ben Burns",
                link: BenBurnsLink,
              }, {
                name: "The Futur",
                link: theFuturLink,
              }]}
              image={{
                src: BenBurnsPrize2,
                width: "305",
                height: "224",
              }}
            />
            <SubPrize
              title={<>“Make a Living Designing Logos” Digital PDF<br />+ Private Circle Logo Geek Community Membership</>}
              subtitle={<div>A book that should be on every brand designer’s bookshelf. Read more about the book <a href="https://www.kickstarter.com/projects/logogeek/make-a-living-designing-logos" target="_blank">here</a></div>}
              authors={[{
                name: "Logo Geek",
                link: logoGeekLink,
              }]}
              image={{
                src: makeALiving,
                width: "295",
                height: "180",
              }}
            />

            <SubPrize
              title="Pangram Pangram Font Starter Pack"
              subtitle={<div>47 Types / 592 Styles Total / All Formats / 30 PSD Mockups. Valued at $29.<br />Read more about their font packs <a href="https://pangrampangram.com/products/fsp" target="_blank">here</a>.</div>}
              authors={[{
                name: "Pangram Pangram",
                link: pangrampangramLink,
              }]}
              image={{
                src: fsp,
                width: "124",
                height: "180",
              }}
            />

            <SubPrize
              title="Web Design Course"
              subtitle={<div>The perfect courses for brand designers looking to get into Web Design. Valued at $50. Read more about their courses <a href="https://hype4.academy/video-courses/web-design-course-2-landing-pages" target="_blank">here</a>.</div>}
              authors={[{
                name: "Hype4 Academy",
                link: hype4AcademyLink,
              }]}
              image={{
                src: webDesignCourse,
                width: "154",
                height: "180",
              }}
            />

            <SubPrize
              title="Bendito Mockup 30% Discount Code"
              subtitle={<div>Get a 30% discount code on their shop. Used by some of the greatest design agencies and brands. Check out their mockups <a href={benditoLink} target="_blank">here</a>.</div>}
              authors={[{
                name: "Bendito Mockup",
                link: benditoLink,
              }]}
              image={{
                src: benditoprize,
                width: "411",
                height: "180",
              }}
            />

            <SubPrize
              title="LS Graphics Unlimited Access for 1 Year"
              subtitle={<div>Get unlimited access to their mockups, illustrations, and Figma Plugin for one full year. Check it out <a href="https://www.ls.graphics/unlimited-access" target="_blank">here</a>. Valued at $120</div>}
              authors={[{
                name: "LS Graphics",
                link: lsGraphicsLink,
              }]}
              image={{
                src: lsGraphicsprize,
                width: "415",
                height: "208",
              }}
            />

            <SubPrize
              title="Get featured on Design Buddies Discord Community"
              subtitle={<div>Get exposure to the largest graphic design community of 100,000+ designers. <a href="https://discord.gg/designbuddies" target="_blank">Join their Discord community here</a>.</div>}
              authors={[{
                name: "Design Buddies",
                link: designBuddiesLink,
              }]}
              image={{
                src: designBuddiesSvg,
                width: "304",
                height: "49",
              }}
            />


            <SubPrize
              title="1 Year of Bravemark PRO + Holographic Stickers"
              subtitle={<div>Get a 1 Year subscription to the PRO tier to create online brand guidelines + unique branded holographic stickers. Read more about Bravemark <a href="https://bravemark.co/" target="_blank">here</a>. Valued at $240.</div>}
              authors={[{
                name: "Bravemark",
                link: bravemarkLink,
              }]}
              image={{
                src: imagePrize2,
                width: "382",
                height: "180",
              }}
            />
            
          </Prize>

          <Prize emoticon="🥉" title="3rd Prize.">

            <SubPrize
              title="“Brand Style Guide Kit by Mr. Ben Burns"
              subtitle={<div>An easy-to-use, 30+ page brand book template for Indesign, Keynote and Adobe XD. Valued at $79. Read more about the Brand Style Kit <a href="https://thefutur.com/course/brand-style-guide-kit" target="_blank">here</a>.</div>}
              authors={[{
                name: "Mr. Ben Burns",
                link: BenBurnsLink,
              }, {
                name: "The Futur",
                link: theFuturLink,
              }]}
              image={{
                src: BenBurnsPrize2,
                width: "305",
                height: "224",
              }}
            />
            <SubPrize
              title="“Make a Living Designing Logos” Digital PDF"
              subtitle={<div>A book that should be on every brand designer’s bookshelf. Read more about the book <a href="https://www.kickstarter.com/projects/logogeek/make-a-living-designing-logos" target="_blank">here</a>.</div>}
              authors={[{
                name: "Logo Geek",
                link: logoGeekLink,
              }]}
              image={{
                src: makeALiving,
                width: "295",
                height: "180",
              }}
            />

            <SubPrize
              title="Pangram Pangram Font Starter Pack"
              subtitle={<div>47 Types / 592 Styles Total / All Formats / 30 PSD Mockups. Valued at $29.<br />Read more about their font packs <a href="https://pangrampangram.com/products/fsp" target="_blank">here</a>.</div>}
              authors={[{
                name: "Pangram Pangram",
                link: pangrampangramLink,
              }]}
              image={{
                src: fsp,
                width: "124",
                height: "180",
              }}
            />

            <SubPrize
              title="Don't be boring Course"
              subtitle={<div>The perfect course to learn how to think out of the box. Valued at $25.<br />Read more about the course <a href="https://hype4.academy/video-courses/don-t-be-boring" target="_blank">here</a>.</div>}
              authors={[{
                name: "Hype4 Academy",
                link: hype4AcademyLink,
              }]}
              image={{
                src: dontBeBoringCourse,
                width: "154",
                height: "180",
              }}
            />

            <SubPrize
              title="Bendito Mockup 15% Discount Code"
              subtitle={<div>Get a 15% discount code on their shop. Used by some of the greatest design agencies and brands. Check out their mockups <a href={benditoLink} target="_blank">here</a>.</div>}
              authors={[{
                name: "Bendito Mockup",
                link: benditoLink,
              }]}
              image={{
                src: benditoprize,
                width: "411",
                height: "180",
              }}
            />

            <SubPrize
              title="LS Graphics Unlimited Access for 1 Year"
              subtitle={<div>Get unlimited access to their mockups, illustrations, and Figma Plugin for one full year. Check it out <a href="https://www.ls.graphics/unlimited-access" target="_blank">here</a>. Valued at $120</div>}
              authors={[{
                name: "LS Graphics",
                link: lsGraphicsLink,
              }]}
              image={{
                src: lsGraphicsprize,
                width: "415",
                height: "208",
              }}
            />

            <SubPrize
              title="Get featured on Design Buddies Discord Community"
              subtitle={<div>Get exposure to the largest graphic design community of 100,000+ designers. <a href="https://discord.gg/designbuddies" target="_blank">Join their Discord community here</a>.</div>}
              authors={[{
                name: "Design Buddies",
                link: designBuddiesLink,
              }]}
              image={{
                src: designBuddiesSvg,
                width: "304",
                height: "49",
              }}
            />


            <SubPrize
              title="6 Months of Bravemark PRO + Holographic Stickers"
              subtitle={<div>Get a 6 Month subscription to the PRO tier to create online brand guidelines + unique branded holographic stickers. Read more about Bravemark <a href="https://bravemark.co/" target="_blank">here</a>. Valued at $120.</div>}
              authors={[{
                name: "Bravemark",
                link: bravemarkLink,
              }]}
              image={{
                src: imagePrize2,
                width: "382",
                height: "180",
              }}
            />
          </Prize>

        </Section>
      
        <Section emoticon="🧐" title="Judging Criteria" classes="judging">
          
          <p>All brand identity design submissions will be scored and judged on the following criteria. Each criteria point will be given a score from 1 to 5 by the different judges through a form once the competition is closed.</p>

          <JudgingCriterium title="Originality" emoticon="💎">
            How unique the brand concept is.
          </JudgingCriterium>

          <JudgingCriterium title="Relevance" emoticon="🫰">
            How well the brand relates to its market, purpose and vision
          </JudgingCriterium>

          <JudgingCriterium title="Memorability" emoticon="🧠">
            How memorable the brand is.
          </JudgingCriterium>

          <JudgingCriterium title="Presentation Quality" emoticon="👁">
            How good and original the brand presentation is.
          </JudgingCriterium>
        </Section>

        <Section emoticon="👨‍⚖️" title="The Judges" classes="judges">
          
          <p>Our different judges come from the world of design and will help select the best brand identity design by giving a score to al the submissions.</p>

          <Judge avatars={[avatarMarkusDaum]} title={<a href={markusDaumLink} alt="Markus Daum">Markus Daum</a>} />
          <Judge avatars={[avatarIan]} title={<>Ian Paget from <a href={logoGeekLink} alt="LogoGeek">LogoGeek</a></>} />
          <Judge avatars={[avatarMichal]} title={<>Michal Malewicz from <a href={hype4AcademyLink} alt="Hype4 Academy">Hype4 Academy</a></>} />
          <Judge avatars={[avatarJohan, avatarMarcus]} title={<>Johan Steneros & Marcus Bergstrom from <a href={bravemarkLink} alt="Bravemark">Bravemark</a></>} />
          <Judge avatars={[avatarGrace]} title={<>Grace Ling from <a href={designBuddiesLink} alt="Design Buddies">Design Buddies</a></>} />
          <Judge avatars={[avatarBenBurns]} title={<>Mr. Ben Burns from <a href={theFuturLink} alt="The Futur">The Futur</a></>} />
          <Judge avatars={[avatarBravemarkCommunity]} title="Bravemark Community Vote" subtitle="Measured by the number of likes a project receives from users on Bravemark" />

        </Section>

        <Section emoticon="✊" title="Sponsors" classes="sponsors">

          <p>Our sponsors help us provide the prizes that we are giving out and get the community started.</p>

          <div className="list">
            <a href={designBuddiesLink}><Image src={designBuddiesSvg} width="304" height="49" /></a>
            <a href={bravemarkLink}><Logo width="120" height="100" /></a>
            <a href={hype4AcademyLink}><Image src={hype4Academy} width="100" height="88" /></a>
            <a href={logoGeekLink}><Image src={logoGeek} width="212" height="45" /></a>
            <a href={pangrampangramLink}><Image src={pangrampangram} width="366" height="28" /></a>
            <a href={benditoLink}><Image src={benditoLogo} width="285" height="61" /></a>
            <a href={theDesignerFoundryLink}><Image src={theDesignerFoundry} width="349" height="28" /></a>
            <a href={markusDaumLink}><Image src={markusDaumLogo} width="59" height="66" /></a>
            <a href={trueGritLink}><Image src={trueGritLogo} width="148" height="92" /></a>
            <a href={lsGraphicsLink}><Image src={lsGraphics} width="66" height="66" /></a>
            <a href={theFuturLink} style={{flexBasis: "100%"}}><Image src={theFuturLogo} width="230" height="49" /></a>
          </div>

          <p>Want to join us as a sponsor? Email us at <br/><a href="mailto:hello@designerhikes.com">hello@designerhikes.com</a></p>
        </Section>

        <Section title="Ready to join the challenge?" classes="end">
          
          <p>To get started, <a href="https://beta.bravemark.co/signin">sign in</a> to your Bravemark account and click the join Designer Hikes Challenge. If you don’t have an account, sign up for Bravemark as a beta user. Once we verified you are a designer we will give you access.</p>

          <Button className="join" to="/join-beta" label="Sign up to Bravemark" size={Button.SIZES.LARGE} />
        </Section>

        <Section emoticon="🏔️" classes="newsletter">
          
          <p>To stay on top of all community news, make sure you also sign up for the Designer Hikes Community Newsletter <a alt="Designer Hikes Community Newsletter" href="https://dashboard.mailerlite.com/forms/839967/113966656077694793/share">here.</a></p>

        </Section>
        
      </Centered>
      
      <Spacer />
    </Page>
  )
}


function Intro({logoTitle}) {

  return (
    <section className="intro">
      <Image rounded
        src={lion}
        alt={logoTitle}
        title={logoTitle}
        width={160}
        height={160}
      />
      <span className="sub">Join the fun and take part in the</span>
      <h1>Designer Hikes Brand Brief Challenge</h1>
      <p className="sub">Take part in the most awesome brand design challenge this year and get a chance to win some awesome prizes.</p>
    </section>
  )
}

function Section({emoticon, title, classes, children}) {

  return (
    <section className={"info " + classes}>
      <span className="emoticon">{emoticon}</span>
      <h2>{title}</h2>
      {children}
    </section>
  )
}

function Prize({emoticon, title, children}) {
  return (
    <div className="prize">
      <span className="emoticon">{emoticon}</span>
      <h3>{title}</h3>
      <div>
        {children}
      </div>
    </div>
  )
}

function SubPrize({title, subtitle, authors, image: {src, width, height}}) {
  const authorsCount = authors.length
  const authorsLinks = authors.reduce((acc, {name, link}, i) => {
    let separator = ", "
    if (i == 0) {
      separator = ""
    } else if (i == authorsCount - 1) {
      separator = " and "
    }

    return <>{acc}{separator}<a href={link}>{name}</a></>
  }, [])
  console.log("NN", authorsLinks)

  return (
    <div className="subPrize">
      <Image
        src={src}
        alt={title}
        title={title}
        width={width}
        height={height}
      />
      <div className="subPrizeTitle">{title}</div>
      <div className="subPrizeSubtitle">{subtitle}</div>
      <div className="subPrizeAuthor">By {authorsLinks}</div>
    </div>
  )
}

function JudgingCriterium({title, emoticon, children}) {
  return (
    <div className="criterium">
      <h3>{title} <span className="emoticon">{emoticon}</span></h3>
      <div>
        {children}
      </div>
    </div>
  )
}

function Judge({avatars, title, subtitle}) {
  return (
    <div className="judge">
      <div className="avatars">
        {avatars.map((img) => <Image rounded key={img} src={img} width="104" height="104" />)}
      </div>
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
  )
}

export default IndexPage
