import React from "react";
import * as moduleStyles from "./Image.module.scss";

export function Image(props) {
  const {
    rounded = false,
    width,
    height,
    ...imgProps
  } = props;

  const classes = [
    moduleStyles.container,
  ]

  return (
    <img className={classes.join(" ")}
      style={{
        borderRadius: rounded ? withUnit(width) : 0,
        "--image-width": withUnit(width),
        "--image-height": withUnit(height),
        "--aspect-ratio": width && height ? width / height : undefined,
      }}
      {...imgProps}
      width={withUnit(width)}
      height={withUnit(height)}
    />
  );
}

function withUnit(dimension) {
  return dimension + "px"
}

